<template>
  <div>
    <FormPartner
      @submitForm="submitForm"
      :partner_id="partner_id"
    ></FormPartner>
  </div>
</template>

<script>
import ApiService from "@/service/api.service";
// import Swal from "sweetalert2";
export default {
  name: "EditPartner",
  components: {
    FormPartner: () => import("./Form"),
  },
  data() {
    return {};
  },
  computed: {
    partner_id: function () {
      return this.$route.params.id;
    },
  },
  created() {},
  methods: {
    async submitForm(data) {
      let vm = this;
      await ApiService.post("prep-app/partner/" + this.partner_id, data)
        .then(function (res) {
          if (res.status == 202) {
            vm.$toasted.success("Submit successfully!!", {
              theme: "toasted-primary",
              position: "top-right",
              duration: 4000,
            });
            vm.$router.push({ name: "ListPartner" });
          }
        })
        .catch(function (error) {
          if (error.response.status == 422) {
            let data_response_error =
              error.response.data.error.message.split(".<br />");
            vm.showError(data_response_error);
          }
        });
    },
    showError(data_error) {
      for (let i = 0; i < data_error.length; i++) {
        this.$toasted.error(data_error[i] + " !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
      }
    },
  },
};
</script>

<style></style>
